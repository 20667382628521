<template>
  <Loading v-if="isLoading" />
  <div v-else class="orders-list">
    <b-card>
      <div class="p-2 mb-1">
        <h1 class="m-0 page-title">{{ $t("orders.orders") }}</h1>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span
            v-if="props.column.label === 'order_number'"
            class="text-nowrap"
          >
            {{ $t("orders.order_number") }}
          </span>
          <span
            v-else-if="props.column.label === 'customer_name'"
            class="text-nowrap"
          >
            {{ $t("orders.customer_name") }}
          </span>
          <span
            v-else-if="props.column.label === 'customer_phone'"
            class="text-nowrap"
          >
            {{ $t("orders.customer_phone") }}
          </span>
          <span
            v-else-if="props.column.label === 'total_price'"
            class="text-nowrap"
          >
            {{ $t("orders.total_price") }}
          </span>
          <span
            v-else-if="props.column.label === 'delivery_boy'"
            class="text-nowrap"
          >
            {{ $t("orders.delivery_boy") }}
          </span>
          <span
            v-else-if="props.column.label === 'order_place'"
            class="text-nowrap"
          >
            {{ $t("orders.order_place") }}
          </span>
          <span
            v-else-if="props.column.label === 'total_count'"
            class="text-nowrap"
          >
            {{ $t("orders.total_count") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("orders.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'order_time'"
            class="text-nowrap"
          >
            {{ $t("orders.order_time") }}
          </span>
          <span
            v-else-if="props.column.label === 'order_date'"
            class="text-nowrap"
          >
            {{ $t("orders.order_date") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <router-link
            :to="
              globalPermission.orders && globalPermission.orders
                ? { name: 'show-order', params: { id: props.row.id } }
                : {}
            "
          >
            <span
              class="text-nowrap"
              v-if="props.column.field === 'order_code'"
            >
              {{ props.row.order_code ? props.row.order_code : "ORD-17" }}
            </span>

            <span
              class="text-nowrap custom-toggle"
              v-else-if="props.column.field === '#'"
            >
              {{ props.row.index }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'customer'"
            >
              {{
                props.row.customer
                  ? props.row.customer.first_name +
                    " " +
                    props.row.customer.last_name
                  : null
              }}
            </span>
            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'phone'"
            >
              {{
                props.row.customer
                  ? props.row.customer.phone.length > 0
                    ? props.row.customer.phone[0].phone
                    : $t("g.no_number")
                  : null
              }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'total_price'"
            >
              {{ props.row.total_price }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'delivery_boy'"
            >
              {{
                props.row.delivery_boy
                  ? props.row.delivery_boy.user.first_name +
                    " " +
                    props.row.delivery_boy.user.last_name
                  : $t("orders.delivery_boy_has_not_been_added_yet")
              }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'address'"
            >
              {{ props.row.address.address_text }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'created_at_date'"
            >
              {{ $helpers.dateTimeInFormat(props.row.created_at) }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'created_at_time'"
            >
              {{ $helpers.timeInFormat(props.row.created_at) }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'expiry_date'"
            >
              {{ $helpers.dateTimeInFormat(props.row.expiry_date) }}
            </span>
            <span
              class="text-nowrap custom-toggle"
              v-else-if="props.column.field === 'status'"
            >
              {{ handleStatus(props.row.status) }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </router-link>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import { BPagination, BCard, BFormCheckbox } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";

export default {
  name: "OrdersList",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    Loading,
    BFormCheckbox,
  },
  data() {
    return {
      isLoading: true,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "order_number",
          field: "order_code",
          sortable: false,
        },
        {
          label: "customer_name",
          field: "customer",
        },
        {
          label: "customer_phone",
          field: "phone",
          sortable: false,
        },
        {
          label: "total_price",
          field: "final_total",
          sortable: false,
        },
        {
          label: "delivery_boy",
          field: "delivery_boy",
          sortable: false,
        },
        {
          label: "order_place",
          field: "address",
          sortable: false,
        },
        {
          label: "total_count",
          field: "items_count",
          sortable: false,
        },
        {
          label: "status",
          field: "status",
          sortable: false,
        },
        {
          label: "order_date",
          field: "created_at_date",
          sortable: false,
        },
        {
          label: "order_time",
          field: "created_at_time",
          sortable: false,
        },
        // {
        //   label: "Actions",
        //   field: "actions",
        // },
      ],
    };
  },
  beforeMount() {
    this.getOrdersList();
  },
  methods: {
    async getOrdersList() {
      try {
        const response = (await this.$http.get("admin/orders")).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          el.index = index + 1;
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteOrders(orderId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handledeleteCoupon(orderId),
          "The order has been successfully deleted"
        );
        this.overlayLoading = true;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handledeleteOrder(orderId) {
      this.$http
        .delete(`admin/orders/${orderId}`)
        .then((res) => {
          if (res.status === 200) {
            this.getOrdersList();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleStatus(array) {
      let status = null;
      array.map((item) => {
        if (item.active_date) {
          status = item;
        }
      });
      return status?.name;
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
